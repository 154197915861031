import React from "react";
import PropTypes from "prop-types";
import { RichText, Text, imageProps } from "@atoms";
import { CardGroup, StackedCard, CompanyLogo } from "@composites";

const HomeCompanies = ({ companies }) => (
  <CardGroup
    gutter={6}
    cards={companies.map(({ name, description, logo }) =>
      React.useCallback(({ className }) => (
        <StackedCard
          top={() => <CompanyLogo logo={logo} className="h-32 md:h-40 -mb-6" />}
          bottom={() => (
            <>
              <Text tag="h3" use="label" className="text-green-3 mb-2">
                {name}
              </Text>
              <RichText
                html={description.html}
                className="RichText--sm text-gray-5"
              />
            </>
          )}
          className={className}
        />
      ))
    )}
  />
);

HomeCompanies.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
      logo: imageProps.isRequired,
    })
  ).isRequired,
};

export default HomeCompanies;
