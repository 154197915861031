import React from "react";
import { useHomePageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import {
  Section,
  Container,
  RawText,
  RichText,
  Pattern,
  SectionBreak,
  Triangle,
  Image,
} from "@atoms";
import { Link, Button } from "@ui";
import {
  CopyBlock,
  ImageBlock,
  CopyImageBlock,
  TestimonialFader,
} from "@composites";
import HomeCompanies from "./HomeCompanies";

const HomePage = pageProps => {
  const {
    hero,
    portfolio,
    philosophyOverview,
    teamOverview,
    testimonials,
    meta,
  } = useHomePageData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {/* Hero */}
      <Section className="relative bg-green-1 pb-32 md:pb-36">
        <Container>
          <div className="max-w-md">
            <RawText items={hero.heading.raw} className="text-white" />

            <SectionBreak delay={500} className="my-6 md:my-10 text-green-3" />

            <RichText
              html={hero.description.html}
              className="RichText--lg text-white mb-6 md:mb-10"
            />

            <Link
              to={hero.ctaLink.url}
              target={hero.ctaLink.target}
              className="inline-block "
            >
              <Button preset="dark" text={hero.ctaText} />
            </Link>
          </div>
        </Container>

        <Pattern
          orientation="bottom-right"
          className="text-green-3"
          doAnimation
        />
      </Section>

      {/* Testimonials */}
      <Section
        className="relative"
        style={{ height: 900, maxHeight: "100vh" }}
        hasPaddingTop={false}
        hasPaddingBottom={false}
        allowOverflow
      >
        <Image image={testimonials.backgroundImage} />

        <TestimonialFader
          testimonials={testimonials.list}
          className="relative z-30 -translate-y-16"
        />

        <div className="absolute inset-0 z-20 overflow-hidden">
          <Pattern
            svgName="HomeHeroPattern"
            orientation="top-right"
            className="text-gray-5 z-20"
          />

          <Pattern
            svgName="HomeHeroPattern"
            orientation="bottom-left"
            size="sm"
            className="text-gray-5 z-20"
          />
        </div>
      </Section>

      {/* Portfolio Overview */}
      <Section className="relative bg-green-1">
        <Pattern
          svgName="HomeHeroPattern"
          orientation="top-left"
          size="sm"
          className="text-gray-3 z-20"
        />

        <Container>
          <CopyImageBlock
            orientation="left"
            copy={({ className, style }) => (
              <CopyBlock
                {...portfolio.overview}
                preset="light"
                className={className}
                style={style}
              />
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={portfolio.overview.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>

      {/* Portfolio Companies */}
      <Section className="bg-green-1" hasPaddingTop={false}>
        <Container>
          <HomeCompanies companies={portfolio.companies} />
        </Container>

        <Pattern
          svgName="HomeHeroPattern"
          orientation="bottom-right"
          size="sm"
          className="text-gray-3 z-20"
        />
      </Section>

      {/* Philosophy */}
      <Section className="bg-white">
        <Pattern
          svgName="HomeHeroPattern"
          orientation="top-right"
          size="sm"
          className="text-gray-3 z-20"
        />

        <Container>
          <CopyImageBlock
            orientation="right"
            copy={({ className, style }) => (
              <CopyBlock
                {...philosophyOverview}
                preset="dark"
                className={className}
                style={style}
              />
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={philosophyOverview.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>

      {/* Team */}
      <Section className="bg-white" allowOverflow>
        <Triangle orientation="bottom-right" className="text-gray-5 -mt-48" />
        <Container class="relative">
          <CopyImageBlock
            orientation="left"
            className="bg-green shadow-section"
            copy={({ style }) => (
              <div className="relative overflow-hidden">
                <CopyBlock
                  {...teamOverview}
                  preset="light"
                  className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
                  style={style}
                />
                <Pattern
                  orientation="bottom-left"
                  size="sm"
                  className="text-gray-2"
                />
              </div>
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={teamOverview.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>
    </DefaultLayout>
  );
};

export default HomePage;
